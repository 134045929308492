/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportResultLogEntryRepresentation
 */
export interface ReportResultLogEntryRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ReportResultLogEntryRepresentation
     */
    time: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResultLogEntryRepresentation
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResultLogEntryRepresentation
     */
    message: string;
}

export function ReportResultLogEntryRepresentationFromJSON(json: any): ReportResultLogEntryRepresentation {
    return ReportResultLogEntryRepresentationFromJSONTyped(json, false);
}

export function ReportResultLogEntryRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportResultLogEntryRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': json['time'],
        'type': json['type'],
        'message': json['message'],
    };
}

export function ReportResultLogEntryRepresentationToJSON(value?: ReportResultLogEntryRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'type': value.type,
        'message': value.message,
    };
}


