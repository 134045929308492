import { i18n } from '@/plugins/i18n';
import type {
    WidgetWizardStep,
    TabDataOptions,
    TabGeneralOptions,
    TabLogEventsOptions,
    TabTableOptions,
} from '@/components/widget-wizard';
import { ResourceType } from '@/modules/shared/types';
import { WidgetWizardTab } from '@/components/widget-wizard';

const logevents: WidgetWizardStep<TabLogEventsOptions> = {
    name: 'widget-wizard.tab.log-events',
    tab: WidgetWizardTab.LogEvents,
    params: {
        enableMinimumDurationFilter: false,
    },
};

const data: WidgetWizardStep<TabDataOptions> = {
    name: 'widget-wizard.tab.data',
    tab: WidgetWizardTab.Data,
    params: {
        enableDuplicateResources: false,
        resourceTypes: [ResourceType.Portfolio, ResourceType.Park, ResourceType.Generator, ResourceType.Shareholder],
        hint: i18n.t('widget-wizard.tab.data.hint-distinct').toString(),
    },
};

const table: WidgetWizardStep<TabTableOptions> = {
    name: 'widget-wizard.tab.table',
    tab: WidgetWizardTab.Table,
    params: {
        defaultSorting: 'startDate',
        defaultSortingDirection: 'desc',
        dataSortingOptions: [
            { value: 'generatorName', displayName: 'widgets.logs.sort-by.generator' },
            { value: 'event', displayName: 'widgets.logs.sort-by.event' },
            { value: 'startDate', displayName: 'time' },
            { value: 'originalMessageId', displayName: 'widgets.logs.sort-by.original-message-id' },
            { value: 'originalMessage', displayName: 'widgets.logs.sort-by.original-message' },
            { value: 'windspeed', displayName: 'widgets.logs.sort-by.wind' },
            { value: 'prodLoss', displayName: 'widgets.logs.sort-by.prodLoss' },
        ],
    },
};

const general: WidgetWizardStep<TabGeneralOptions> = {
    name: 'widget-wizard.tab.general',
    tab: WidgetWizardTab.General,
};

export const Steps: WidgetWizardStep[] = [
    logevents,
    data,
    table,
    general,
];
