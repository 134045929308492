/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreamingResponseBody
 */
export interface StreamingResponseBody {
    /**
     * 
     * @type {Blob}
     * @memberof StreamingResponseBody
     */
    raw?: Blob;
}

export function StreamingResponseBodyFromJSON(json: any): StreamingResponseBody {
    return StreamingResponseBodyFromJSONTyped(json, false);
}

export function StreamingResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamingResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'raw': !exists(json, 'raw') ? undefined : json['raw'],
    };
}

export function StreamingResponseBodyToJSON(value?: StreamingResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'raw': value.raw,
    };
}


