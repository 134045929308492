/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportResultLogEntryRepresentation,
    ReportResultLogEntryRepresentationFromJSON,
    ReportResultLogEntryRepresentationFromJSONTyped,
    ReportResultLogEntryRepresentationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportResultRequest
 */
export interface ReportResultRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportResultRequest
     */
    jobId: number;
    /**
     * 
     * @type {string}
     * @memberof ReportResultRequest
     */
    documentKey: string;
    /**
     * The version for which the content of document is generated
     * @type {number}
     * @memberof ReportResultRequest
     */
    version: number;
    /**
     * 
     * @type {Array<ReportResultLogEntryRepresentation>}
     * @memberof ReportResultRequest
     */
    logs: Array<ReportResultLogEntryRepresentation>;
}

export function ReportResultRequestFromJSON(json: any): ReportResultRequest {
    return ReportResultRequestFromJSONTyped(json, false);
}

export function ReportResultRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportResultRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': json['jobId'],
        'documentKey': json['documentKey'],
        'version': json['version'],
        'logs': ((json['logs'] as Array<any>).map(ReportResultLogEntryRepresentationFromJSON)),
    };
}

export function ReportResultRequestToJSON(value?: ReportResultRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobId': value.jobId,
        'documentKey': value.documentKey,
        'version': value.version,
        'logs': ((value.logs as Array<any>).map(ReportResultLogEntryRepresentationToJSON)),
    };
}


