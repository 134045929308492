/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogDataRepresentation
 */
export interface LogDataRepresentation {
    /**
     * 
     * @type {string}
     * @memberof LogDataRepresentation
     */
    generatorKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof LogDataRepresentation
     */
    isDummy: boolean;
    /**
     * 
     * @type {string}
     * @memberof LogDataRepresentation
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof LogDataRepresentation
     */
    originalMessageId?: string;
    /**
     * 
     * @type {string}
     * @memberof LogDataRepresentation
     */
    originalMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof LogDataRepresentation
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof LogDataRepresentation
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof LogDataRepresentation
     */
    startDate: number;
    /**
     * 
     * @type {number}
     * @memberof LogDataRepresentation
     */
    endDate?: number;
    /**
     * 
     * @type {number}
     * @memberof LogDataRepresentation
     */
    durationInSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof LogDataRepresentation
     */
    windspeed?: number;
    /**
     * 
     * @type {number}
     * @memberof LogDataRepresentation
     */
    prodLoss?: number;
}

export function LogDataRepresentationFromJSON(json: any): LogDataRepresentation {
    return LogDataRepresentationFromJSONTyped(json, false);
}

export function LogDataRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogDataRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generatorKey': json['generatorKey'],
        'isDummy': json['isDummy'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'originalMessageId': !exists(json, 'originalMessageId') ? undefined : json['originalMessageId'],
        'originalMessage': !exists(json, 'originalMessage') ? undefined : json['originalMessage'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startDate': json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'durationInSeconds': !exists(json, 'durationInSeconds') ? undefined : json['durationInSeconds'],
        'windspeed': !exists(json, 'windspeed') ? undefined : json['windspeed'],
        'prodLoss': !exists(json, 'prodLoss') ? undefined : json['prodLoss'],
    };
}

export function LogDataRepresentationToJSON(value?: LogDataRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generatorKey': value.generatorKey,
        'isDummy': value.isDummy,
        'event': value.event,
        'originalMessageId': value.originalMessageId,
        'originalMessage': value.originalMessage,
        'color': value.color,
        'description': value.description,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'durationInSeconds': value.durationInSeconds,
        'windspeed': value.windspeed,
        'prodLoss': value.prodLoss,
    };
}


